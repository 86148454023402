import axios from "./axios";

const getRestaurantCategoryList = (id) => {
  return axios.post(`/categoryWithRestaurantId`, { restaurantId: id }).then(
    (res) => {
      return res;
    },
    (error) => {
      return error.response;
    }
  );
};

const getSubCategoryByCategory = (id) => {
  return axios.post(`/subCategoryByCategoryId`, { categoryId: id }).then(
    (res) => {
      return res;
    },
    (error) => {
      return error.response;
    }
  );
};

const getItemBySubCategory = (id) => {
  return axios.post(`/itemBySubCategoryId`, { subCategoryId: id }).then(
    (res) => {
      return res;
    },
    (error) => {
      return error.response;
    }
  );
};

const getMenuCategoryAndSubCat = (id) => {
  return axios.post(`/getMenuCategoryandSubcategoryByRestaurantId?restaurantId=${id}`).then(
    (res) => {
      return res;
    },
    (error) => {
      return error.response;
    }
  );
};

const getMenuItemsBySubCategory = (params) => {
  return axios.get(`/getMenuItemsBySubCategory`, { params }).then(
    (res) => {
      return res;
    },
    (error) => {
      return error.response;
    }
  );
};

export {
  getRestaurantCategoryList,
  getSubCategoryByCategory,
  getItemBySubCategory,
  getMenuCategoryAndSubCat,
  getMenuItemsBySubCategory,
};
